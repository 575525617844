/**
 * @fileOverview
 * todo 这文件干嘛使的
 * @author xiaoF
 * @since 2021/3/16.
 */
'use strict';
import { fetchApi } from "@/utils/axios";
import URL from "@/api/urlConfig"

// 模糊查询数据
/**
 * 获取广告列表
 * @param {Object} params 参数对象
 * @param {*} params.advertisement_content // 广告内容（改名为广告标题）
 * @param {*} params.advertisement_end_time // 广告结束时间
 * @param {*} params.advertisement_start_time // 广告开始时间
 * @param {*} params.display	//广告启禁用状态
 * @param {*} params.page // 页数
 * @param {*} params.size // 每页数量
 * @param {*} params.template_type // 模板类型
 * @returns
 */
export function getHomePageModulesList(params) {
  const submitParams = {
    ...params,
    // 透出端口暂时写死, 当前页面是适配新版APP
    penetrate: 1,
    // 展示模块暂时不传
    module_id_list: [],
    display: params.display === '' ? undefined : params.display
  };
  return fetchApi(URL.GET_AD_LIST_NEW, submitParams, 'post', 'data');
}

// 新建
export function insertAdvertising(data) {

  return fetchApi(URL.ADD_ADVERTISING, data, 'post', 'data');
}

// 修改 更新
export function updateAdvertising(data) {
  return fetchApi(URL.UPDATE_ADVERTISING, data, 'post', 'data');
}

// 启用 停用状态更新
export function updateState(data) {
  const params = {
    ids: [data.id],
    display: data.display,
  };
  return fetchApi(URL.ADVERTISEMENT_DISPLAY_STATUS_UPDATE, params, 'post', 'data');
}

// 删除广告
export function advertisementDelete(ids) {
  return fetchApi(URL.ADVERTISEMENT_DELETE, {ids}, 'post', 'data');
}

// 检查是否可以启用
export function checkCanOpen(data) {
  const params = {
    advertisementStartTime: data.advertisementStartTime,
    advertisementEndTime: data.advertisementEndTime,
    projectList: JSON.stringify(data.projectList),
    type: data.type
  };
  return fetchApi(URL.CHECK_CAN_OPEN, params, 'post');
}

/**
 * 根据id查广告详情
 * @param {String} id 广告id
 * @returns
 */
export function findOneForAdvertising(id,projectId) {
  return fetchApi(`${URL.GET_ADVERTISING_DETAIL_BY_ID}/${id}`, {projectId});
}

// 获取广告位的项目列表
export function getAdvertisingProjectList() {
  return fetchApi(URL.GET_ALL_PROJECT_LIST);
}

/**
 * 获取所有模块列表
 * @returns 模块列表
 */
export function getAllModuleList() {
  const params = {
    page: 1,
    size: 9999,
    projectId: null,
    pagePosition: null,
    moduleName: null
  };
  return fetchApi(URL.GET_ALL_MODULE_LIST, params, 'post', 'data');
}
export function getShunyiAllProject(data) {
  return fetchApi(URL.GET_SHUNYI_ALL_PROJECTLIST,data, 'post', 'data',true);
}

/**
 * 根据项目id和显示位置查询模块列表
 * @param {Object} params 参数对象
 * @param {Object} params.projectId 项目id
 * @param {Object} params.type 模版类型 默认传4(广告位)
 * @param {Object} params.pagePosition 页面位置
 * @returns 返回模块列表
 */
export function getModuleListByProjectidAndLocation(params) {
  return fetchApi(
    `${URL.GET_MODULE_LIST_BY_PROJECTID_AND_LOCATION}/${params.projectId}/${params.type}/${params.pagePosition}`,
    {}
  );
}

// 获取所有项目
export function getProjectList() {
  const params = {
    'paging.page': 0,
    'filter.name': 'project_all',
    'filter.params': 'null',
    'paging.size': 1000
  };
  return fetchApi(URL.GET_PROJECT_LIST2, params);
}

export function getAllProjectByUserAuth () {
  return fetchApi(URL.GET_ALL_PROJECT_BY_USER_AUTH + '?authority=AD_MANAGE')
}
