<style lang="scss" scoped>
.item {
  margin: 4px;
}
.productName {
  display: -webkit-box;
  /* 需要对老版本的WebKit浏览器做兼容 */
  display: -ms-box;
  /* 对IE10的支持 */
  display: box;
  -webkit-line-clamp: 1;
  /* 设置显示的行数 */
  -webkit-box-orient: vertical;
  /* 设置布局方向为垂直 */
  overflow: hidden;
  /* 隐藏超出的内容 */
  text-overflow: ellipsis;
}
.contentTips {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

::v-deep.el-table .cell {
  padding-left: 0 !important;
}

.l-search {
  display: flex;
  flex-wrap: wrap;
  padding: 0 15px;
  margin-bottom: 20px;
  flex-wrap: wrap;

  &>div {
    box-sizing: border-box;
    min-width: 33.3333%;
    display: flex;
    // flex: 1;
    padding: 10px 10px;

    .el-input,
    .el-select {
      flex: 1;
    }

    &>span {
      width: 110px;
      line-height: 30px;
      padding-right: 10px;
      font-size: 14px;
    }
  }

  .snx-button {
    flex: 1;
    display: flex;
    padding: 0 10px;
    justify-content: flex-end;
  }
}
</style>

<template>
  <div>
    <div class="snx-wrap" style="overflow: hidden">
      <ds-header title="广告管理"></ds-header>
      <a-form layout="inline">
        <a-form-item label="广告标题">
          <a-input v-model="search.advertisement_content" placeholder="请输入标题" />
        </a-form-item>
        <a-form-item label="状态">
          <a-select default-value="" v-model="search.display" style="width: 200px">
            <a-select-option :value="''">
              全部
            </a-select-option>
            <a-select-option :value="1">
              启用
            </a-select-option>
            <a-select-option :value="0">
              停用
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="广告时间">
          <a-range-picker :ranges="pickerOptions2" v-model="search.timeArea" />
        </a-form-item>
        <a-form-item>
          <a-button style="margin-right: 20px;" @click="handleAdd" type="primary"> 添加新广告 </a-button>
          <a-button style="margin-right: 20px;" @click="handleQuery" type="primary"> 查询 </a-button>
          <a-button @click="resetSearch"> 重置 </a-button>
        </a-form-item>
      </a-form>
      <div style="margin-top: 10px">
        <a-table bordered :rowKey="(record, index) => record.industry_id" :childrenColumnName="'child_list'" :columns="columns"
          :data-source="tableData" @change="pageChange" :pagination="pagination" 
          :scroll="{ x: 'max-content' }">
          <span slot="action" slot-scope="text, record">
            <a-button type="link" v-if="record.display == '0'" @click="stateOpen(record)">启用</a-button>
            <a-button type="link" v-if="record.display == '1'" @click="stateClose(record)">停用</a-button>
            <a-button type="link" @click="handleEdit(record)" v-if="record.display == '0'">修改</a-button>
            <a-button type="link" @click="handleDelete(record.id)" v-if="record.display == '0'">删除</a-button>
          </span>
          <span slot="advertisement_content" slot-scope="text, record">
            <a-tooltip placement="topLeft">
              <template slot="title">
                <span>{{ record.advertisement_content }}</span>
              </template>
              <span class="productName">{{ record.advertisement_content }}</span>
            </a-tooltip>
          </span>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
/**
 * TODO fileOverview
 * --------------
 * @author xiaof
 * @since 2021/3/4.
 */
import {
  getHomePageModulesList,
  updateState,
  getAdvertisingProjectList,
  getAllModuleList,
  advertisementDelete
} from '../../../api/advertisingNew/advertisingNew';
import moment from 'moment';

// 显示位置选项集合
const locationOptions = [
  { id: '', value: '全部' },
  { id: 0, value: '首页' },
  { id: 1, value: '食住行' },
  { id: 2, value: '无忧创业' },
  { id: 3, value: '我的权益' }
];

export default {
  name: 'advertisingManagement',
  data() {
    return {
      pickerOptions2: {
        '近3天': [moment(new Date(Date.now() - 3600 * 1000 * 24 * 3)), moment(new Date())],
        '近7天': [moment(new Date(Date.now() - 3600 * 1000 * 24 * 7)), moment(new Date())],
        '近30天': [moment(new Date(Date.now() - 3600 * 1000 * 24 * 30)), moment(new Date())],
      },
      search: {
        timeArea: [],
        // 状态
        display: '',
        // 广告标题
        advertisement_content: '',
        // 模版类型
        template_type: ''
      },
      pagination: {
        pageSizeOptions: ['10', '20', '30', '50', '100'],
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
        showQuickJumper: true,
        showTotal: (total, range) => {
          return `共${total}条`
        }
      },
      columns: [
        {
          title: "广告标题",
          dataIndex: "advertisement_content",
          key: "advertisement_content",
          scopedSlots: { customRender: "advertisement_content" },
          width: '200px',
        },
        {
          title: "透出端口",
          dataIndex: "penetrate",
          key: "penetrate",
          width: '100px',
          customRender: (text, row, index) => {
            return text === 1 ? 'APP' : '小程序';
          }
        },
        {
          title: "可见项目",
          dataIndex: "projectName",
          key: "projectNames",
          width: '300px',
        },
        {
          title: "模版类型",
          dataIndex: "template_type",
          key: "template_type",
          width: '200px',
          customRender: (text, row, index) => {
            return text === 0 ? '单张胶囊广告' : '一拖二轮播广告';
          },
        },
        {
          title: "位置",
          dataIndex: "pagePosition",
          key: "pagePosition",
          width: '100px',
        },
        {
          title: "优先级",
          dataIndex: "priority",
          key: "priority",
          width: '100px',
        },
        {
          title: "发布人",
          dataIndex: "update_user",
          key: "update_user",
          width: '100px',
        },
        {
          title: "发布时间",
          dataIndex: "gmt_create",
          key: "gmt_create",
          width: '200px',
        },
        {
          title: "更新时间",
          dataIndex: "gmt_modified",
          key: "gmt_modified",
          width: '200px',
        },
        {
          title: "广告时间",
          dataIndex: "formatStartTime",
          key: "formatStartTime",
          width: '200px',
        },
        {
          title: "状态",
          dataIndex: "display",
          key: "display",
          width: '100px',
          customRender: (text, row, index) => {
            return text === 0 ? '停用' : '启用';
          },
        },
        {
            title: "操作",
            key: "action",
            scopedSlots: { customRender: "action" },
            fixed: 'right',
            width:'220px'
          },
      ],
      tableData: [],
      project: [],
      page: 1,
      size: 10,
      total: 0,
      // 显示位置选项集合
      locationOptions,
      // 模块集合
      moduleObj: {}
    };
  },
  created() {
    // this.projectList();
    this.getModuleList();
  },
  methods: {
    pageChange(pageOption) {
      this.pagination.current = pageOption.current
      this.pagination.pageSize = pageOption.pageSize
      this.getList(this.pagination.current - 1, this.pagination.pageSize)
    },
    // projectList() {
    //   getAdvertisingProjectList().then(res => {
    //     this.project = res.items;
    //   });
    // },
    // 获取模块列表
    async getModuleList() {
      try {
        const result = await getAllModuleList();
        if (result.code === '200') {
          this.moduleList = result.data.appModuleList;
          result.data.appModuleList.forEach(item => {
            this.moduleObj[item.id] = item;
          });
          sessionStorage.setItem('MODULE_OBJ', JSON.stringify(this.moduleObj));
          this.getList(this.pagination.current - 1, this.pagination.pageSize);
        }
      } catch (error) { }
    },
    // 重置检索条件
    async resetSearch() {
      this.search = {
        timeArea: [],
        display: '',
        advertisement_content: '',
        template_type: ''
      };
      
      this.pagination.current = 1
      this.pagination.pageSize = 10
      const data = {
        // 广告开始时间
        advertisement_start_time: null,
        // 广告结束时间
        advertisement_end_time: null,
        display: null,
        page: 0,
        size: 10,
        advertisement_content: '',
        template_type: ''
      };
      this.searchData(data);
    },
    async getList(page, size) {
      const data = {
        advertisement_start_time:
          this.search.timeArea[0] === undefined
            ? ''
            : moment(this.search.timeArea[0]).format('YYYY-MM-DD'),
        advertisement_end_time:
          this.search.timeArea[1] === undefined
            ? ''
            : moment(this.search.timeArea[1]).format('YYYY-MM-DD'),
        display: this.search.display,
        page,
        size,
        template_type: 0,// this.search.template_type,
        advertisement_content: this.search.advertisement_content
      };
      this.searchData(data);
    },
    // 查询数据
    async searchData(data) {
      try {
        let res = await getHomePageModulesList(data);
        if (res.code === '200') {
          this.tableData = res.data.data.map(item => {
            item['formatStartTime'] = moment(
              item.advertisement_start_time
            ).format('YYYY-MM-DD');
            item['formatEndTime'] = moment(item.advertisement_end_time).format(
              'YYYY-MM-DD'
            );
            item['format_gmt_create'] = moment(item.gmt_create).format(
              'YYYY-MM-DD'
            );
            const module = this.handleListLocationAndShowProjects(
              item.module_id
            );
            item['projectName'] = module.projectName;
            item['pagePosition'] = this.backShowLocation(module.pagePosition);
            return item;
          });
          this.pagination.total = res.data.total;
        }
      } catch (error) { }
    },
    handleQuery() {
      this.getList(this.pagination.current - 1, this.pagination.pageSize);
    },
    handleAdd() {
      this.$router.push({
        path: "/advertising/newAdvertisingManagement/add",
        query: {
          type: "add",
        },
      });
    },
    handleEdit(record) {
      this.$router.push({
        path: "/advertising/newAdvertisingManagement/add",
        query: { id:record.id,moduleId:record.module_id, moduleObj: this.moduleObj, type: "edit" }
      });
    },
    async handleDelete(id) {
      const that = this;
      try {
        this.$confirm({
          content: '是否删除该广告?', title: '提示',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          showClose: false,
          onOk: async () => {
            const result = await advertisementDelete([id]);
            if (result.success) {
              that.$message.success(result.msg);
              that.getList(that.pagination.current - 1, that.pagination.pageSize);
            } else {
              that.$message.error(result.msg);
            }
          },
          onCancel() {
            console.log('Cancel');
          },
        })
      } catch (error) {
        that.$message.success('操作失败');
      }
    },
    stateOpen(row) {
      const that = this;
      try {
        this.$confirm({
          content: '是否启用该广告?', title: '提示',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          showClose: false,
          onOk: async () => {
            let data = {
              id: row.id,
              display: 1
            };
            try {
              let res = await updateState(data);
              if (res.code === '200') {
                that.$message.success('操作成功');
                that.getList(that.pagination.current - 1, that.pagination.pageSize);
              } else {
                that.$message.error(res.msg);
              }
            } catch (error) {
              this.$message.error(JSON.parse(error.request.response).msg );
             console.log(JSON.parse(error.request.response).msg  ,'=================>');
            }
          },
          onCancel() {
            console.log('Cancel');
          },
        })
      } catch (error) {
        console.log(error)
        that.$message.error('接口报错');
      }
    },
    async stateClose(row) {
      const that = this;
      try {
        this.$confirm({
          content: '是否禁用该广告?',
          title: '提示',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          showClose: false,
          onOk: async () => {
            let data = {
              id: row.id,
              display: 0
            };
            let res = await updateState(data);
            if (res.code === '200') {
              that.$message.success('操作成功');
              that.getList(that.pagination.current - 1, that.pagination.pageSize);
            } else {
              that.$message.error(res.msg);
            }
          },
          onCancel() {
            console.log('Cancel');
          },
        })
      } catch (error) {
        that.$message.error('接口报错');
      }
    },

    /**数据处理 */
    // 处理列表数据显示位置和可见项目
    handleListLocationAndShowProjects(module_id) {
      const module = this.moduleObj[module_id];
      return module ? module : { projectName: '暂无', pagePosition: '' };
    },

    // 返回显示位置
    backShowLocation(pagePosition) {
      switch (pagePosition) {
        case 0:
          return '首页';
        case 1:
          return '食住行';
        case 2:
          return '无忧创业';
        case 3:
          return '我的权益';
        case 4:
          return '积分商城';
        default:
          return '暂无';
      }
    }
  }
};
</script>
